import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import Sidebar from 'components/sidebar';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The Power Box`}</h1>
    <p><strong parentName="p">{`Day 10 - Today`}</strong></p>
    <p>{`Blush! You took your first steps yesterday :)`}</p>
    <p>{`I think that means you’re ready to learn my favorite productivity weapon. These
are the nuclear codes of getting sh`}{`*`}{`t done.`}</p>
    <p>{`It’s how I juggle all the things I have to do, without getting stressed out.`}</p>
    <p>{`I call it the `}<strong parentName="p">{`powerbox.`}</strong></p>
    <p>{`I thought I invented the power box, and it was genius. I was right and wrong. It
is genius, but I didn't invent it. It turns out it's called an Eisenhower
Matrix.`}</p>
    <p>{`Right now I’m juggling so many things. My day job. My podcast. The new startup
fund. The all access pass content. Etc..`}</p>
    <p>{`So today, i woke up and realized that I needed to refresh my powerbox to make
sure I know how to prioritize today’s tasks.`}</p>
    <p>{`Alright blushie - grab a crayon, let’s draw an example powerbox.`}</p>
    <p>{`Here's how it works. Make a cross on your page (like an X and Y axis)`}</p>
    <p>{`The Y axis is `}<strong parentName="p">{`impact`}</strong>{`, the X axis is `}<strong parentName="p">{`urgency.`}</strong></p>
    <p>{`Now start thinking off all those “to dos” in your head, and put them in the
right spot, depending on the impact/urgency for each.`}</p>
    <p>{`Here's mine for today:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1396px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABi0lEQVQoz5WTWW+CQBSF5///KxM1PqghJoqpgEtcihvQgtaltB9z7JTUp54HcmfuPXc5dzBf/8H9fv+wwOBonKMsy9vtdj6fXy02m810Og3DEPv9B6fTCWae52maYlfk6/X6ZoEb8uFw6HQ6o9Go1+uNx+Pj8UjeT4vtduv7vm6yLKvI0OBzViLIlO33+3EcqyP3nUwmzWYziiKKF0VRkSmoGUTGvVgscJO0PhRfuoVJZRqE9SAzrQva7/dJkpCF+7pa4jMzqen5b+XSQqFMqNkcuOEeTqvVQki8FZnZyOQ6d6DJ58qQB4PBbDZDF8MBAdGWlaB+XsNyuXQ2YXwJkJbtdns4HBpqYnW73fV6LbdA9Gq1Kmrg8nK5MCNh+homYTfaintG0q/eNvq9WEhFFkHM7yNREI00Gg1GwuZJ1IWkMl7l1dMwUp/dOFXU2263m8/nSArn+ZFru0Z9QiZZZsEwHIMgYJ8koj5HonFRBg4FSPqorK3iIFQ/jdTyPI998mZgEso9RmIhOb4B5QVZO68EklQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The Power Box",
          "title": "The Power Box",
          "src": "/static/73ca4725acb5694d274092e809215276/bcfad/powerbox.png",
          "srcSet": ["/static/73ca4725acb5694d274092e809215276/d9f49/powerbox.png 400w", "/static/73ca4725acb5694d274092e809215276/78d47/powerbox.png 800w", "/static/73ca4725acb5694d274092e809215276/bcfad/powerbox.png 1396w"],
          "sizes": "(max-width: 1396px) 100vw, 1396px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This exercise makes it really clear what to do. I start in the top right. Then I
hit the bottom right. Then I hit the top left.`}</p>
    <p>{`When i was running bebo, it was very common to feel overwhelmed, overloaded, or
like i was drowning in shit to do, so i used this to help me figure out what to
prioritize`}</p>
    <p><strong parentName="p">{`/alright, now you know how to use powerboxing to bucket your tasks, and not
get stressed out.`}</strong></p>
    <p>{`So for today - the highest impact / highest urgency thing to do is to narrow
down ecommerce ideas so that I can order samples (which might take a month to
arrive). If you wanna hear me and ben discuss that in a 7-min call we had this
morning,
`}<a parentName="p" {...{
        "href": "https://soundcloud.com/shaan-puri-429311607/all-access-pass-91520-daily-standup/s-EwjDmxep7kD"
      }}>{`click here`}</a>{`.`}</p>
    <p>{`I looked at my list, and I’m not loving any of my ecomm ideas. I don’t have that
rush of conviction that one of these is a winner.`}</p>
    <p>{`Hmm.`}</p>
    <p>{`Fuck, ok going to take a snack break.`}</p>
    <p><strong parentName="p">{`Back from snack break - realized it’s time to come up with some more ideas and
use my phone-a-friend lifeline.`}</strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://twitter.com/naterez94"
        }}>{`Friend #1 - Nathan from Sourcify`}</a></strong>{`.`}</p>
    <p>{`Nathan is the founder of sourcify, his story is crazy. In high school, he became
a foreign exchange student in china to improve his manadarin. While in China, he
started buying/selling small knockoff items from chinese markets on ebay..and
then graduated up to the real game - sourcing real products from manufacturers
and selling them in the states.`}</p>
    <p>{`His company, `}<a parentName="p" {...{
        "href": "https://www.sourcify.com/"
      }}>{`Sourcify`}</a>{`, helps source products for
businesses. And he himself has run multiple 7 figure ecommerce stores. If he
can’t help us, nobody can.`}</p>
    <p>{`I don’t really know Nathan, but he listens to the podcast so I feel like a cold
email will work. I ask Ben to reach out for me, while I brainstorm more ideas:`}</p>
    <p><strong parentName="p">{`An hour later, we do a phone call. Here’s Nathan’s take:`}</strong></p>
    <ul>
      <li parentName="ul">{`A couple of your ideas (some from the new list and some from the old list) are
good (eg. kids play couch)`}</li>
      <li parentName="ul">{`The apple cider vinegar gummies idea is good, but food formulation/supplements
take time`}</li>
      <li parentName="ul">{`Would avoid doing things that a lot of dropshippers are doing (eg. theragun
competitor)`}</li>
    </ul>
    <p><strong parentName="p">{`He has a few ideas of his own:`}</strong></p>
    <ul>
      <li parentName="ul">{`He’s been travelling a lot (road tripping) and saw the need for “inflatable
car beds” or “car tents” - maybe we could even brand these as companion
accessories for specific cars (eg. Ford F150 or Tesla) -
`}<a parentName="li" {...{
          "href": "https://soundcloud.com/shaan-puri-429311607/chat-w-nathan-from-sourcify/s-imN7m62OiOY"
        }}>{`here’s an audio snippet of us talking about this idea`}</a></li>
      <li parentName="ul">{`A chair or desk massager, for people working from home and sitting all day`}</li>
      <li parentName="ul">{`Car police-radar detector (as discussed on the podcast)`}</li>
    </ul>
    <p><strong parentName="p">{`And some things to think about:`}</strong></p>
    <ul>
      <li parentName="ul">{`Sourcing lead time - since this is a timed challenge, need to pick products
that we can source quickly. His team is expert at this, and he’s offered to
help`}</li>
      <li parentName="ul">{`Margin (target 70%+ gross margin)`}</li>
      <li parentName="ul">{`Competitive landscape`}</li>
    </ul>
    <p>{`OK - felt really good about Nathan & sourcify. Cool guy, really happy to help.
I’m def gonna use Sourcify for the quotes/sourcing phase once we pick our
product idea.`}</p>
    <p><strong parentName="p">{`Friend #2 - Alex - founded a big co in the beauty ecomm space`}</strong></p>
    <p>{`Next - we hopped on a call with our buddy Alex. Alex is a whiz kid who’s last
company did tens of millions a year in ecommerce revenue.`}</p>
    <p><a parentName="p" {...{
        "href": "https://soundcloud.com/shaan-puri-429311607/chat-w-alex-soundbyte/s-xWR9TJBINBS"
      }}>{`Here’s an audio snippet from our conversation`}</a>{`..
And some of the biggest takeaways:`}</p>
    <ul>
      <li parentName="ul">{`Don’t be married to facebook ads. He’s had a lot of success w/ influencers and
is down to help us if we go that route.`}</li>
      <li parentName="ul">{`One option is to partner with an influencer and launch the brand to their
audience directly. This has worked well (eg. Rihanna launching Fenty to her
audience, or Kylie Jenner’s lip line)`}</li>
      <li parentName="ul">{`One idea - Silk bed sheets. He just bought some, and it’s getting rave
reviews.. High price point, high perceived value.`}</li>
      <li parentName="ul">{`Of all our ideas, he likes the kids couch the best, but is concerned about
shipping since it’s going to be like `}{`$`}{`30-40 shipping to get it to a customer.`}</li>
    </ul>
    <p>{`Alright - that’s the execution deep dive for today. Brainstorming with friends.`}</p>
    <p>{`We have a few more calls lined up tomorrow, a bit of research to do... and then
will decide which products to get quotes/samples from after that.`}</p>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://soundcloud.com/shaan-puri-429311607/all-access-pass-91520-daily-standup/s-EwjDmxep7kD',
        label: '9.15.20 Daily Standup'
      }, {
        url: 'https://soundcloud.com/shaan-puri-429311607/chat-w-nathan-from-sourcify/s-imN7m62OiOY',
        label: 'Chat with Nathan from Sourcify'
      }, {
        url: 'https://soundcloud.com/shaan-puri-429311607/chat-w-alex-soundbyte/s-xWR9TJBINBS',
        label: 'Chat with Alex'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/naterez94',
        label: 'Nathan from Sourcify'
      }, {
        url: 'https://www.sourcify.com/',
        label: 'Sourcify'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.impulse_vs_repeat_vs_splurge_products.label,
      slug: slugify(postMap.impulse_vs_repeat_vs_splurge_products.slug)
    }} nextButton={{
      text: postMap.validating_demand_for_product_ideas.label,
      slug: slugify(postMap.validating_demand_for_product_ideas.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      